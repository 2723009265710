
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customer-financial-statement-sme-other-income-expense",
  components: {
    Form,
    ElCurrencyInputNoSymbol,
  },

  data() {
    const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const totalMonths = 0;

    const statements = {
      otherIncome: {
        label: "Other Income",
        total: 0,
        child: {
          rentalIncome: {
            label: "Rental Income",
            value: [],
            total: 0,
          },
          others: {
            label: "Others (To specify)",
            value: [],
            total: 0,
          },
          interestIncome: {
            label: "Interest Income",
            value: [],
            total: 0,
          },
        },
      },
      totalOtherIncome: {
        label: "Total Other Income",
        value: [],
        // total:0,
      },
      otherExpenses: {
        label: "Other Expenses",
        total: 0,
        child: {
          marketing: {
            label: "Marketing",
            value: [],
            total: 0,
          },
          salaries: {
            label: "Salaries",
            value: [],
            total: 0,
          },
          rental: {
            label: "Rental",
            value: [],
            total: 0,
          },
          electricityWater: {
            label: "Electricity & Water",
            value: [],
            total: 0,
          },
          storageFees: {
            label: "Storage Fees",
            value: [],
            total: 0,
          },
          insurance: {
            label: "Insurance",
            value: [],
            total: 0,
          },
          deliveryFees: {
            label: "Delivery Fees",
            value: [],
            total: 0,
          },
          generalExpenses: {
            label: "General Expenses",
            value: [],
            total: 0,
          },
          phoneInternet: {
            label: "Phone & Internet",
            value: [],
            total: 0,
          },
        },
      },
      totalOtherExpenses: {
        label: "Total Other Expenses",
        value: [],
        // total:0,
      },
      interestExpenses: {
        label: "Interest Expenses",
        total: 0,
        child: {
          interestOverdraft: {
            label: "Interest-Overdraft",
            value: [],
            total: 0,
          },
          interestTermLoans: {
            label: "Interest-Term loans",
            value: [],
            total: 0,
          },
          interestOthers: {
            label: "Interest-Others",
            value: [],
            total: 0,
          },
        },
      },
      totalInterestExpenses: {
        label: "Total Interest Expenses",
        value: [],
        // total:0,
      },
    };

    // const totalMTD ={
    //   totalOtherIncomeMTD: {
    //     label: "Total Other Income MTD",
    //     total: 0,
    //   },

    //   totalOtherExpensesMTD: {
    //     label: "Total Other Expenses MTD",
    //     total: 0,
    //   },

    //   totalInterestMTD : {
    //     label: "Total Interest MTD",
    //     total: 0,
    //   }
    // }

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
    };

    return {
      router: useRouter(),
      isLoading,
      months,
      totalMonths,
      statements,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },

  async mounted() {
    setCurrentPageBreadcrumbs("Other Incomes and Expenses", [
      "Customer",
      "Financial Statements SME",
      "Income Statement",
    ]);
    this.init();
  },

  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
          });
      }
      this.initMonths();
    },

    initMonths() {
      for (let index = 0; index < this.months.length; index++) {
        this.statements.totalOtherIncome.value.push(0);
        this.statements.totalOtherExpenses.value.push(0);
        this.statements.totalInterestExpenses.value.push(0);
      }

      for (let key of Object.keys(this.statements.otherIncome.child)) {
        let otherIncomeChild = this.statements.otherIncome.child;
        for (let index = 0; index < this.months.length; index++) {
          otherIncomeChild[key].value.push(0);
        }
      }

      for (let key of Object.keys(this.statements.otherExpenses.child)) {
        let otherExpensesChild = this.statements.otherExpenses.child;
        for (let index = 0; index < this.months.length; index++) {
          otherExpensesChild[key].value.push(0);
        }
      }

      for (let key of Object.keys(this.statements.interestExpenses.child)) {
        let interestExpensesChild = this.statements.interestExpenses.child;
        for (let index = 0; index < this.months.length; index++) {
          interestExpensesChild[key].value.push(0);
        }
      }
    },

    calculateTotalIncome() {
      for (let i = 0; i < this.statements.totalOtherIncome.value.length; i++) {
        this.statements.totalOtherIncome.value[i] = 0;
      }

      let result = 0;
      for (let keyChild of Object.keys(this.statements.otherIncome.child)) {
        let otherIncomeChild = this.statements.otherIncome.child[keyChild];
        let stateChildSubValue = otherIncomeChild.value;

        otherIncomeChild.total = stateChildSubValue.reduce(
          (sum, item) => sum + item
        );

        for (
          let i = 0;
          i < this.statements.totalOtherIncome.value.length;
          i++
        ) {
          this.statements.totalOtherIncome.value[i] += stateChildSubValue[i];
        }

        result += otherIncomeChild.total;
        this.statements.otherIncome.total = result;
      }
    },

    calculateTotalExpense() {
      for (
        let i = 0;
        i < this.statements.totalOtherExpenses.value.length;
        i++
      ) {
        this.statements.totalOtherExpenses.value[i] = 0;
      }

      let result = 0;
      for (let keyChild of Object.keys(this.statements.otherExpenses.child)) {
        let otherExpensesChild = this.statements.otherExpenses.child[keyChild];
        let stateChildSubValue = otherExpensesChild.value;

        otherExpensesChild.total = stateChildSubValue.reduce(
          (sum, item) => sum + item
        );

        for (
          let i = 0;
          i < this.statements.totalOtherExpenses.value.length;
          i++
        ) {
          this.statements.totalOtherExpenses.value[i] += stateChildSubValue[i];
        }

        result += otherExpensesChild.total;
        this.statements.otherExpenses.total = result;
      }
    },

    calculateTotalInterest() {
      for (
        let i = 0;
        i < this.statements.totalInterestExpenses.value.length;
        i++
      ) {
        this.statements.totalInterestExpenses.value[i] = 0;
      }
      let result = 0;
      for (let keyChild of Object.keys(
        this.statements.interestExpenses.child
      )) {
        let interestExpensesChild = this.statements.interestExpenses.child[
          keyChild
        ];
        let stateChildSubValue = interestExpensesChild.value;

        interestExpensesChild.total = stateChildSubValue.reduce(
          (sum, item) => sum + item
        );

        for (
          let i = 0;
          i < this.statements.totalInterestExpenses.value.length;
          i++
        ) {
          this.statements.totalInterestExpenses.value[i] +=
            stateChildSubValue[i];
        }

        result += interestExpensesChild.total;
        this.statements.interestExpenses.total = result;
      }
    },

    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 8,
        raw: {
          statements: this.statements,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("updated");
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("saved");
          });
      }
    },
    swalMessage(m) {
      Swal.fire({
        title: "Congratulation!",
        text:
          "Other Income &nbsp; Operational Expenses Statement has been " +
          m +
          "!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        this.router.push({
          path: `/customers/details/${this.cstmr_id}/financial-statements/sme/list`,
        });
      });
    },
  },
});
